import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'eng-trial-popup',
  templateUrl: './trial-popup.component.html',
  styleUrls: ['./trial-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialPopupComponent implements AfterViewInit {
  @ViewChild('popup') public popup!: ElementRef;
  @ViewChild('pseudoImg') public pseudoImg!: ElementRef;
  @ViewChild('title') public title!: ElementRef;

  maxHeight = 'auto';
  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.intHeight();
  }

  intHeight() {
    if (this.popup && this.pseudoImg && this.title) {
      this.setHeight();
    }
  }

  setHeight(_popup?: HTMLDivElement, _pseudoImg?: HTMLDivElement, _title?: HTMLHeadingElement) {
    this.maxHeight =
      (this.popup?.nativeElement.offsetHeight ?? 0) -
      (this.pseudoImg?.nativeElement.offsetHeight ?? 0) -
      (this.title?.nativeElement.offsetHeight ?? 0) +
      'px';
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setHeight();
  }
}
