<div class="img">
  <img src="/assets/img/popup/not-successful.svg" alt="img" />
</div>
<div class="popup" #popup>
  <div class="pseudo-img" #pseudoImg></div>
  <h2 class="accent text-align-center title bold-5" #title>
    {{ 'payment.trialPeriodPassed' | translate }}
  </h2>
  <div [style.height]="maxHeight">
    <eng-all-subscriptions></eng-all-subscriptions>
  </div>
</div>
