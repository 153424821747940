import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PopupsService } from '@shared/popups/popups.service';
import { TrialPopupComponent } from './trial-popup/trial-popup.component';
import { SettingsState } from '@state/settings/settings.state';
import { CurrentUserState } from '@state/current-user/current-user.state';

@Injectable({
  providedIn: 'root',
})
export class AddGameService {
  constructor(
    private popupsService: PopupsService,
    private router: Router,
    private settingsState: SettingsState,
    private currentUserState: CurrentUserState,
  ) {}

  add() {
    if (!this.currentUserState.canAddMyGame) {
      this.popupsService.showStatus({
        text: `Dear teacher, you have reached the maximum limit of free games
        (${this.currentUserState.myMaxGamesCount}). Please contact our team via email: lilibip.info@gmail.com`,
        img: 'popup/not-successful.svg',
        back: 'default.back',
        titleClass: 'h4',
      });
    } else if (this.isActivePlan()) {
      this.router.navigate(['/account/choose-game']);
    } else {
      this.popupsService
        .showDialog(TrialPopupComponent, { panelClasses: 'trial' })
        .afterClosed()
        .subscribe(() => {});
    }
  }

  isActivePlan() {
    return true; // TODO isActivePlan
  }
}
